import React, { useEffect, useState } from 'react';
import Button from '../../../components/button';
import { navigate } from 'gatsby';
import { callAppFunc } from '../../../utils/jsbridge/index.js';
import HeaderBar from '../../../components/header-bar/index.jsx';
import { useAPI } from '../../../utils/api';
import { showLoading, dismissLoading } from '../../../utils/loading';
import { setInsiderCustomEvent } from 'utils/_insider';

const PaymentFailPage = ({ location, transNo }) => {
  const api = useAPI();

  const [isBackMain, setBackMain] = useState(false);
  const [reason, setReason] = useState('');

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const from = params.get('from');
    const reason = params.get('reason');
    if (from != null) {
      setBackMain(true);
    } else {
      setBackMain(false);
    }
    if (reason != null) {
      setReason(reason);
    }

    setInsiderCustomEvent('failed_order', {});
  }, []);

  const goCart = () => {
    showLoading();
    api
      .reOrder(transNo)
      .then((res) => {
        dismissLoading();
        if (!res) {
          return;
        }

        // alert('已完成下單');
        navigate('/cart', {
          state: { page: '' },
          replace: true,
        });
      })
      .catch((error) => {
        dismissLoading();
        console.log('reorder exception =', error);
      });
  };

  return (
    <>
      <div className="header">
        <HeaderBar
          title={'付款失敗'}
          goBack={() => {
            if (isBackMain) {
              callAppFunc('backMain', {});
            } else {
              callAppFunc('backHome', {});
            }
          }}
        />
      </div>
      <div className="payment-fail-page">
        <h2 className="title">支付失敗</h2>
        <p>抱歉，您的訂單支付失敗</p>
        <div className="message">{reason}</div>
        <Button
          text="重新下單"
          onClick={() => {
            goCart();
          }}
        />
      </div>

      <style jsx>
        {`
          .payment-fail-page {
            padding: 16px;

            .title {
              font-size: 24px;
              margin-bottom: 16px;
            }

            p {
              margin-bottom: 16px;
            }

            .message {
              border: 1px solid #9b0000;
              background-color: #ff00004d;
              color: #f00000;
              padding: 16px;
              border-radius: 4px;
              margin-bottom: 16px;
            }
          }
        `}
      </style>
    </>
  );
};

export default PaymentFailPage;
